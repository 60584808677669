jQuery(function () {

    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $("header").sticky({
        topSpacing: 0,
        zIndex: 999,
    });

    $('.search-toggle').on('click', function(){
        $('.search-wrapper').slideToggle()
    })

    $(".cart-toggle").on("click", function () {
        $(".cart-dropdown").toggleClass("show");
        setTimeout(() => {
            $(".cart-dropdown.show").removeClass("show");
        }, 3000);
    });

    $('.cart-close').on("click", function(){
        $(".cart-dropdown").removeClass("show");
    })

    $('.FirstPage,.LastPage,.BackPage,.NextPage').parent().remove()

    if ($(window).width() < 1024) {
        $(".has-mega .toggle").on("click", function () {
            $(this).next().slideToggle();
            $(this).toggleClass("ri-arrow-down-s-line ri-arrow-up-s-line");
        });
    } else {
        megaMenuHover();
        try {
            $('.history-wrap').css({
                'padding-left': $('.about-2 .container').offset().left + 15
            })
            $('.staff-list-wrap').css({
                'padding-left': $('.about-2 .container').offset().left + 15
            })
            
            window.dispatchEvent(new Event("resize"));
        } catch (error) {
            
        }
        $(window).resize(function(){
            try {
                $('.history-wrap').css({
                    'padding-left': $('.about-2 .container').offset().left + 15
                })
                $('.staff-list-wrap').css({
                    'padding-left': $('.about-2 .container').offset().left + 15
                })
                window.dispatchEvent(new Event("resize"));
            } catch (error) {
                
            }
        })
    }

    $(document).on("click", function (e) {
        if (
            $(".productsearchbox").has(e.target).length === 0 &&
            $(".suggestsearch").has(e.target).length === 0
        ) {
            $(".suggestsearch").remove();
            $(".searchinput").val("");
        }
    });

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("strong").toggleText("Xem thêm", "Rút gọn");
        $(this).find("span").toggleClass("lnr-chevron-down lnr-chevron-up");
    });

    $('.product-detail-other').before($('.product-contact-section'))

    $(".product-detail-attr .tab-content .expand-content").each(function () {
        if ($(this).height() < 400) {
            $(this).next().hide();
        } else {
            $(this).next().show();
        }
    });

    $(".btn-detail-contact").click(function (e) {
        e.preventDefault();
        scrollToElement($(this).attr("href"), 2000);
    });

    collapseComponent()
    productSideNav()
    productDetailSlide()
    productSlider()
});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

var scrollToElement = function (el, ms) {
    var speed = ms ? ms : 2000;
    $("html,body").animate(
        {
            scrollTop: $(el).offset().top - $("header").height(),
        },
        speed
    );
};


function megaMenuHover() {
    $(".link-lv-1").mouseenter(function () {
        var idHover = $(this).data("index");
        $(".zone-child-lv-2-wrap").hide();
        $(`.zone-child-lv-2-wrap[data-index="${idHover}"]`).show();
    });
    $(".link-lv-2 li").mouseenter(function () {
        var idHover = $(this).data("index");
        $(".zone-child-lv-3-wrap").hide();
        $(`.zone-child-lv-3-wrap[data-index="${idHover}"]`).show();
    });
}

function collapseComponent() {
	$(document).on("click", ".collapse-trigger", function () {
		$(this).next().slideToggle();
		$(this).toggleClass("active");
		return false;
	});
}

function productSideNav() {
	$(document).on("click", ".toggle-item-lv-1 .trigger", function () {
		$(this).parent().next().slideToggle();
		$(this).parent().toggleClass("active");
        $(this).parents('.sidenav-item-lv-1').siblings().children('.body-item-lv-1').slideUp()
        $(this).parents('.sidenav-item-lv-1').siblings().children('.toggle-item-lv-1 ').removeClass('active')
		return false;
	});

	$(document).on("click", ".toggle-item-lv-2 .trigger", function () {
		$(this).parent().next().slideToggle();
		$(this).parent().toggleClass("active");
        $(this).parents('.sidenav-item-lv-2').siblings().children('.body-item-lv-2').slideUp()
        $(this).parents('.sidenav-item-lv-2').siblings().children('.toggle-item-lv-2 ').removeClass('active')
		return false;
	});
}


const menuBottomMapping = new MappingListener({
    selector: ".header-bottom-wrap",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".header-top-wrap",
    desktopMethod: "insertAfter",
    breakpoint: 1024.98,
}).watch();

const bannerHome = new Swiper(".home-banner .swiper-container",{
    pagination: {
        el: '.home-banner .swiper-pagination',
        clickable: true
    }
})

const homeProjectSlider = new Swiper(".project-home-slider .swiper-container",{
    slidesPerView: 1.45,
    breakpoints: {
        1024: {
            slidesPerView: 1.25,
            spaceBetween: 15
        }
    },
    navigation: {
        prevEl: ".project-home-slider .swiper-prev",
        nextEl: ".project-home-slider .swiper-next"
    },
})

const projectThumbnailSlider = new Swiper(
    ".project-detail-thumb-slider .swiper-container",
    {
        spaceBetween: 20,
        slidesPerView: 3,
        direction: "vertical",
        slideToClickedSlide: true,
        navigation: {
            prevEl: ".project-detail-thumb-slider .swiper-prev",
            nextEl: ".project-detail-thumb-slider .swiper-next"
        },
        breakpoints: {
            1024: {
                direction: "horizontal",
            },
            768: {
                direction: "horizontal",
                spaceBetween: 10,
                slidesPerView: 2.25,
            },
        },
    }
);

const projectImagesSlider = new Swiper(
    ".project-detail-images-slider .swiper-container",
    {
        spaceBetween: 15,
        thumbs: {
            swiper: projectThumbnailSlider,
        },
    }
);

const projectSlider = new Swiper(
    ".project-slider .swiper-container",
    {
        spaceBetween: 30,
        slidesPerView: 3,
        navigation: {
            prevEl: ".project-slider .swiper-prev",
            nextEl: ".project-slider .swiper-next"
        },
        breakpoints: {
            1024: {
                slidesPerView: 2.5
            },
            768: {
                slidesPerView: 1.75,
            },
            576: {
                slidesPerView: 1.25,
                spaceBetween: 15
            },
        },
    }
);

const historyYearSlider = new Swiper(
    ".history-year-slider .swiper-container",
    {
        slidesPerView: 3.75,
        breakpoints: {
            1024: {
                slidesPerView: 2.5
            },
            768: {
                slidesPerView: 1.75,
            },
            576: {
                slidesPerView: 1.25,
                spaceBetween: 15
            },
        },
        navigation: {
            prevEl: ".history-year-slider .swiper-prev",
            nextEl: ".history-year-slider .swiper-next"
        },
    }
);

const historyContentSlider = new Swiper(
    ".history-content-slider .swiper-container",
    {
        slidesPerView: 3.75,
        spaceBetween: 30,
        breakpoints: {
            1024: {
                slidesPerView: 2.5
            },
            768: {
                slidesPerView: 1.75,
            },
            576: {
                slidesPerView: 1.25,
                spaceBetween: 15
            },
        },
    }
);

try {
    historyYearSlider.controller.control = historyContentSlider;
    historyContentSlider.controller.control = historyYearSlider;
} catch (error) {
    
}

var partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 4,
    slidesPerColumn: 2,
    navigation: {
        prevEl: ".partner-slider .swiper-prev",
        nextEl: ".partner-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 3.5,
        },
        768: {
            slidesPerView: 2.5,
        },
        576: {
            slidesPerView: 2.65,
        },
    },
});

function productDetailSlide() {
    var productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 10,
            observer: true,
            observeParents: true,
            breakpoints: {
                768: {
                    slidesPerView: 3.25,
                    spaceBetween: 10,
                },
                576: {
                    slidesPerView: 2.25,
                    spaceBetween: 10,
                },
            },
        }
    );

    var productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productDetailThumbnail,
            },
        }
    );
}

function productSlider() {
    $(".product-slider").each(function (index) {
        var $this = $(this);
        if ($(this).data("slides") !== undefined) {
            var initSlide = $(this).data("slides");
        } else {
            var initSlide = 4;
        }
        $this.addClass("product-slider-" + index);
        $this.find(".swiper-prev").addClass("product-slider-prev-" + index);
        $this.find(".swiper-next").addClass("product-slider-next-" + index);
        $this
            .find(".swiper-pagination")
            .addClass("product-slider-pagination-" + index);

        var productSlider = new Swiper(
            ".product-slider-" + index + " .swiper-container",
            {
                slidesPerView: initSlide,
                spaceBetween: 30,
                navigation: {
                    prevEl: ".product-slider-prev-" + index,
                    nextEl: ".product-slider-next-" + index,
                },
                pagination: {
                    el: ".product-slider-pagination-" + index,
                    clickable: true,
                },
                breakpoints: {
                    1280: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 2.75,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2.15,
                        spaceBetween: 15,
                    },
                    768: {
                        slidesPerView: 1.55,
                        spaceBetween: 15,
                    },
                },
            }
        );
    });
}